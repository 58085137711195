// src/components/Auth/Login.js
import React, { useState } from 'react';
import api from '../../api'; // Import the configured axios instance
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();



  const isSessionStorageAvailable = () => {
    try {
      sessionStorage.setItem("test", 21);
      const xx = sessionStorage.getItem("test");
  
      if (xx === "21") {  // Compare with "21" since sessionStorage stores values as strings
        sessionStorage.removeItem("test"); // Clean up test data
        // console.log("xx", xx);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!isSessionStorageAvailable()) {
      alert(
        "Your browser's security settings currently prevent this program from functioning properly.\n\n" +
        "To resolve this issue:\n" +
        "1. Ensure that cookies and site data are allowed in your browser settings.\n" +
        "2. Disable any privacy or ad-blocking extensions (such as Privacy Badger or uBlock).\n" +
        "3. Avoid using private or incognito mode.\n\n" +
        "Once these adjustments are made, please refresh the page and try again."
    );
    return;
    }

    try {
      const response = await api.post('/api-token-auth/', {
        username,
        password,
      });

      const { token, user_id, company_id, is_superuser } = response.data; // Assume your backend returns user_id along with the token
      localStorage.setItem('token', response.data.token);
      // localStorage.setItem('user_id', user_id); // Store user ID in local storage
      // localStorage.setItem('company_id', company_id); // Store user ID in local storage
      // localStorage.setItem('is_baap', is_superuser); // Store user ID in local storage

      // sessionStorage.setItem('token', response.data.token);
      sessionStorage.setItem('user_id', user_id); // Store user ID in local storage
      sessionStorage.setItem('company_id', company_id); // Store user ID in local storage
      sessionStorage.setItem('is_baap', is_superuser); // Store user ID in local storage

      // console.log('user_id', user_id);

      onLogin();
      navigate('/dashboard');
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Error logging in');
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="6">
          <Card>
            <Card.Body>
              <Card.Title className="text-center mb-4">Login</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formUsername" className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100">
                  Login
                </Button>
              </Form>
              <div className="text-center mt-3">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;

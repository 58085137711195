import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Rnd } from 'react-rnd';  // For draggable and resizable components
import { Table, Button, Card, Container, Row, Col } from 'react-bootstrap';  // For displaying data
import api from '../../api';
import Hls from 'hls.js';  // Import Hls.js for HLS streaming
import moment from 'moment';
//import './FloatingVideo.css';  // Custom styles for floating video
 import './InterviewDetail.css';  // Custom styles for floating video

const InterviewDetail = () => {
  const { id } = useParams();
  const [interview, setInterview] = useState(null);
  const [responses, setResponses] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');  // URL of the video to play
  const [isVideoFloating, setIsVideoFloating] = useState(false);
  const videoRef = useRef(null);  // Reference to the video element
  const hlsRef = useRef(null);    // Reference to Hls.js instance

// *************************************************************
const [xPosition, setXPosition] = useState(0);
const [yPosition, setYPosition] = useState(0);
// **************************************************************




  // Detect if the browser is Safari
  const isSafari = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes('safari') && !userAgent.includes('chrome');
  };




  useEffect(() => {
    // Fetch interview details and responses
    const fetchInterviewDetail = async () => {
      try {
        const response = await api.get(`/api/get_interview_details/${id}/`);
        setInterview(response.data[0]); 
      } catch (error) {
        console.error('Error fetching interview details:', error);
      }
    };

    const fetchInterviewResponses = async () => {
      try {
        const response = await api.get(`/api/get_interview_responses/${id}/`);
        setResponses(response.data);
      } catch (error) {
        console.error('Error fetching interview responses:', error);
      }
    };

    fetchInterviewDetail();
    fetchInterviewResponses();
  }, [id]);

  useEffect(() => {
    // Reinitialize the video whenever the video URL changes
    if (videoUrl && videoRef.current) {
      initializeVideo(videoUrl);
    }
    // }, [videoUrl]);
  },);

  const initializeVideo = (url) => {
    setIsVideoFloating(true);  // Show the floating video player

    if (isSafari()) {
      // Safari supports HLS natively
      if (videoRef.current) {
        videoRef.current.src = url;
        videoRef.current.play();  // Auto-play for Safari
      }
    } else if (Hls.isSupported()) {
      // Non-Safari browsers: use Hls.js for HLS streaming
      if (hlsRef.current) {
        hlsRef.current.destroy();  // Clean up any previous HLS instance
      }
      const hls = new Hls();
      hls.loadSource(url);  // Load the HLS URL
      hls.attachMedia(videoRef.current);  // Attach HLS to the video element
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoRef.current.play();  // Auto-play when manifest is parsed
      });
      hlsRef.current = hls;  // Store the Hls.js instance
    } else {
      // Fallback for unsupported browsers
      if (videoRef.current) {
        videoRef.current.src = url;
        videoRef.current.play();
      }
    }
  };

  const playVideo = (url) => {
    setVideoUrl(url);  // Set the video URL when button is clicked

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    // Set x and y to place the video in the bottom-right corner of the browser
    const x = windowWidth - 700 + scrollX; // 20px from the right edge
    const y = windowHeight - 580 + scrollY; // 20px from the bottom edge

    setXPosition(x);
    setYPosition(y);

    setIsVideoFloating(true);  // Show the floating video window
  };

  const closeVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();  // Pause the video
      videoRef.current.removeAttribute('src');  // Remove the video source to stop playback
      videoRef.current.load();  // Reset the video element
    }

    if (hlsRef.current) {
      hlsRef.current.destroy();  // Destroy the hls.js instance when closing
      hlsRef.current = null;
    }

    // Hide the floating video player
    setIsVideoFloating(false);
  };

  return (
    <Container className="interview-detail">
      <Row className="justify-content-center">
        <Col md={12}>
          <Card className="shadow-sm mb-6">
            <Card.Body>
              <Card.Title className="text-center mb-8">Interview Details</Card.Title>
              {interview && (
                <div className="interview-info">
                  <p><strong>Candidate:</strong> {interview.candidate.first_name} {interview.candidate.last_name}</p>
                  <p><strong>Job Title:</strong> {interview.job.title}</p>
                  <p><strong>Location:</strong> {interview.job.location}</p>
                  <p><strong>Client:</strong> {interview.job.company}</p>
                </div>
              )}
            </Card.Body>
          </Card>

          <h5 className="text-center">Responses</h5>
          <Table striped bordered hover className="responses-table">
            <thead>
              <tr>
                <th>Question Number</th>
                <th>Question and Response</th>
                <th>Analysis</th>
                <th>Points</th>
                <th>Question Start Time</th>
                <th>Video</th>
              </tr>
            </thead>
            <tbody>
              {responses.map((response) => (
                <tr key={response.id}>
                  <td>{response.questionnumber}</td>
                  <td>
                    <strong>Question:</strong> {response.question_text}
                    <br />
                    <strong>Response:</strong> {response.response_text}
                  </td>
                  <td>{response.AIAnalysis}</td>
                  <td>{response.AIPoints}</td>
                  <td>{moment(response.questionstarttime).format('MM/DD/YY HH:mm:ss')}</td>
                  <td>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => playVideo(response.video_url)}  // Set the video URL when button is clicked
                      disabled={!response.video_url || response.video_url === ""}
                    >
                      Play Video
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {isVideoFloating && (
          <Rnd
          default={{
            width: 480,
            height: 360,
            x: xPosition,
            y: yPosition,
          }}
          dragHandleClassName="video-drag-handle"
          className="floating-video"
          style={{
            position: 'fixed', 
            zIndex: 1000,
          }}
          
        >

          <div className="video-container">
            <div className="video-header">
              <span className="video-drag-handle">Drag Me</span>
              <button className="close-button" onClick={closeVideo}>✖</button>
            </div>
            <video
              ref={videoRef}
              controls
              width="100%"
              height="100%"
            >
              {isSafari() ? (
                <source src={videoUrl} type="application/x-mpegURL" />
              ) : null}
            </video>
          </div>
        </Rnd>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default InterviewDetail;



import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Table, Form, Badge, Pagination, Container, InputGroup } from 'react-bootstrap';
import './CommandCenter.css';
import { getInterviews } from '../../api';
import moment from 'moment'; // Import moment.js for date formatting
import api from '../../api';
import { getUserId, getCompanyId} from '../../utils/auth'; // Import the utility function

const CommandCenter = () => {
  const [interviews, setInterviews] = useState([]);
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('start_time');
  const [sortDirection, setSortDirection] = useState('desc');
  const navigate = useNavigate();

  useEffect(() => {
    fetchOngoingInterviews(currentPage, searchTerm, pageSize, sortField, sortDirection);
  }, [currentPage, pageSize, sortField, sortDirection]);

  const fetchOngoingInterviews = async (page, searchTerm = '', pageSize = 10, sortField = 'start_time', sortDirection = 'desc') => {
    try {
      const data = await getInterviews(page, pageSize, searchTerm, sortField, sortDirection, getCompanyId());
      setInterviews(data.results);
      setTotalPages(Math.ceil(data.count / pageSize));
    } catch (error) {
      console.error('Error fetching ongoing interviews:', error);
    }
  };

  const handlePlanClick = async (interview) => {
    try {
      const response = await api.get(`/api/get_interview_questions/${interview.id}/`);
      setQuestions(response.data);
      setSelectedInterview(interview.id);
      setModalTitle(`${interview.candidate_first_name} ${interview.candidate_last_name} - ${interview.job.title}`);
    } catch (error) {
      console.error('Error fetching interview plan:', error);
    }
  };



  const handleGenerateLink = async (interviewId) => {
    try {
      const data = {
        interview_id: interviewId,
      };
      const result = await api.post('generate_interview_link/', data);

      // const response = await api.post('/generate_interview_link/', { interview_id: interviewId });
      alert('Interview link and passcode have been sent via email.');
    } catch (error) {
      console.error('Error generating interview link:', error);
      alert('Failed to send the interview link. Please try again.');
    }
  };

  const handleToggleSkipStep = async (questionId, currentSkipStep) => {
    try {
      await api.patch(`/api/update_question/${questionId}/`, {
        skip_step: !currentSkipStep,
      });
      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question.id === questionId ? { ...question, skip_step: !currentSkipStep } : question
        )
      );
    } catch (error) {
      console.error('Error updating skip step:', error);
    }
  };

  const handleInterviewClick = (interviewId) => {
    navigate(`/interview/${interviewId}`);
  };

  const formatDate = (date) => {
    return date && moment(date).isValid() ? moment(date).format('MM/DD/YY HH:mm:ss') : '';
  };

  const renderStatus = (interview_started, interview_ended, time_exceeded) => {
    if (interview_started === 0 && interview_ended === 0) {
      return <Badge bg="secondary">Scheduled</Badge>;
    }
    if (interview_started === 1 && interview_ended === 0) {
      if (time_exceeded > 5400)
        return <Badge bg="info">Abandoned</Badge>;
      else
        return <Badge bg="success">Live</Badge>;
    }
    if (interview_started === 1 && interview_ended === 1) {
      return <Badge bg="primary">Completed</Badge>;
    }
    if (interview_started === 1 && interview_ended === 2) {
      return <Badge bg="danger">Terminated</Badge>;
    }
    return null;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);  // Reset to first page on new search
    fetchOngoingInterviews(1, searchTerm, pageSize, sortField, sortDirection);
  };

  const handleSort = (field) => {
    const newSortDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(newSortDirection);
    fetchOngoingInterviews(currentPage, searchTerm, pageSize, field, newSortDirection);
  };

  return (
    <Container className="command-center">
      <h2>Command Center</h2>

      {/* Search and Pagination Controls */}
      <Form className="mb-4" onSubmit={handleSearch}>
        <InputGroup>
          <Form.Control
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by name, job title, or client"
          />
          <Button variant="primary" type="submit">
            <i className="bi bi-search me-2"></i> Search
          </Button>
        </InputGroup>
        <div className="page-size-container">
          <Form.Group controlId="pageSize" className="mt-3">
            <Form.Label>Page Size</Form.Label>
            <Form.Control
              as="select"
              value={pageSize}
              onChange={(e) => {
                setPageSize(parseInt(e.target.value));
                setCurrentPage(1);  // Reset to the first page when page size changes
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={50}>50</option>


            </Form.Control>
          </Form.Group>
        </div>
      </Form>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => handleSort('candidate_first_name')}>Candidate</th>
            <th onClick={() => handleSort('job_title')}>Job Title</th>
            <th>Location</th>
            <th onClick={() => handleSort('job_company')}>Client</th>
            <th>Status</th>
            <th onClick={() => handleSort('start_time')}>Start time</th>
            <th>End time</th>
            <th>Actions</th>
            <th>Actions</th>
            <th>Generate Link</th>
          </tr>
        </thead>
        <tbody>
          {interviews.map((interview) => (
            <tr key={interview.id}>
              <td>{interview.candidate_first_name} {interview.candidate_last_name}</td>
              <td>{interview.job_title}</td>
              <td>{interview.job_location}</td>
              <td>{interview.job_company}</td>
              <td>{renderStatus(interview.interview_started, interview.interview_ended, interview.time_exceeded)}</td>
              <td>{formatDate(interview.start_time)}</td>
              <td>{formatDate(interview.end_time)}</td>
              <td>
                <Button variant="primary" size="sm" onClick={() => handleInterviewClick(interview.id)}>Interview</Button>
              </td>
              <td>
                <Button variant="secondary" size="sm" onClick={() => handlePlanClick(interview)} disabled={!(interview.interview_started === 1 && interview.interview_ended === 1)}
                >Plan</Button>
              </td>
              <td>
                <Button
                  variant="info"
                  size="sm"
                  onClick={() => handleGenerateLink(interview.id)}
                  disabled={!(interview.interview_started === 1 && interview.interview_ended === 1)}
                >
                  Link
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      {totalPages > 1 && (
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

          {/* Logic for showing page numbers */}
          {currentPage > 2 && <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>}
          {currentPage > 3 && <Pagination.Ellipsis />}

          {/* Display a range of pages around the current page */}
          {Array.from({ length: totalPages }, (_, i) => i + 1)
            .filter((page) => page >= currentPage - 1 && page <= currentPage + 1)
            .map((page) => (
              <Pagination.Item key={page} active={page === currentPage} onClick={() => handlePageChange(page)}>
                {page}
              </Pagination.Item>
            ))}

          {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
          {currentPage < totalPages - 1 && (
            <Pagination.Item onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>
          )}

          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      )}
      
      <Modal show={!!selectedInterview} onHide={() => setSelectedInterview(null)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {questions.map((question) => (
            <Form.Group key={question.id} className="mb-3">
              <Form.Label><strong>Instruction:</strong> {question.aiuser_instruction}</Form.Label>
              <Form.Check
                type="switch"
                id={`skip-step-${question.id}`}
                label="Skip Step"
                checked={question.skip_step}
                onChange={() => handleToggleSkipStep(question.id, question.skip_step)}
                disabled={question.instructionexecuted === 1}
              />
            </Form.Group>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedInterview(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CommandCenter;


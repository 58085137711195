// src/api.js
import axios from 'axios';

const api = axios.create({
   baseURL: 'https://adjango2.azurewebsites.net/'
    //  baseURL: 'http://localhost:8000/',
    // baseURL: 'http://192.168.6.181:8000/',
   
});



api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);



export const getInterviews = async (page = 1, pageSize = 10, searchTerm = '', sortField = 'start_time', sortDirection = 'desc', usercompany) => {
  try {
    const response = await api.get('/api/commandcenterinterviews/', {
      params: {
        page: page,
        page_size: pageSize,
        search: searchTerm,
        ordering: `${sortDirection === 'desc' ? '-' : ''}${sortField}`,
        usercompany: usercompany,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching interviews:', error);
    throw error;
  }
};

export default api;
